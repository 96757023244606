<template>
    <v-dialog
        :value="true"
        max-width="600"
        persistent
    >
        <v-card v-if="finished">
            <v-card-title class="d-flex justify-space-between align-center">
                <span class="text-h6 font-weight-bold">Configurações concluídas! 🎉</span>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="text-body-1 text-center pa-4" style="height: 120px;">
                Agora é com a gente! Iremos concluir o cadastramento dos produtos e, assim que estiver tudo pronto, avisaremos você pelo WhatsApp!
            </v-card-text>
        </v-card>
        <v-card v-else>
            <v-card-title>
                Boas vindas, {{ getUserName }}! 👋
            </v-card-title>
            <div class="ma-6">
                <span class="text-subtitle">
                    Finalize as configurações básicas, leva menos de 5 minutos.
                </span>
            </div>
            <v-divider/>
            <v-card-text class="mt-4">
                <v-stepper v-model="stepper" vertical elevation="0">
                    <v-stepper-step :complete="stepper > 1" step="1">
                        Configurar Endereço Comercial
                    </v-stepper-step>

                    <v-stepper-content step="1">
                        <v-form ref="formEndereco">
                            <v-row class="mt-1">
                                <v-col cols="6" class="py-0">
                                    <v-text-field
                                        @keypress.enter="consultaCep"
                                        :loading="loading.cep"
                                        label="CEP"
                                        type="text"
                                        v-model="form.cep"
                                        :rules="[rules.cep]"
                                        v-mask="['#####-###']"
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-select
                                        :items="ufs"
                                        label="UF"
                                        v-model="form.uf"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field
                                        label="Municipio"
                                        v-model="form.municipio"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field
                                        label="Rua/Avenida"
                                        v-model="form.rua"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field
                                        label="Bairro"
                                        v-model="form.bairro"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field
                                        label="Número"
                                        v-model="form.numero"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-card-actions>
                            <v-spacer/>
                            <v-btn
                                @click="goToSecondStep"
                                color="primary"
                                class="mt-4"
                                width="130"
                                large
                            >Continuar</v-btn>
                        </v-card-actions>
                    </v-stepper-content>

                    <v-stepper-step :complete="stepper > 2" step="2">
                        Configurar Chave Pix
                    </v-stepper-step>

                    <v-stepper-content step="2">
                        <v-form ref="formPix">
                            <v-row class="mt-1">
                                <v-col cols="12" class="py-0">
                                    <v-text-field
                                        label="Chave Pix"
                                        v-model="form.chave_pix"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-text-field
                                        label="Nome do Recebedor"
                                        v-model="form.nome_pix"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                    />
                                </v-col>
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-select
                                        v-model="form.tipo_pix"
                                        :rules="[rules.empty]"
                                        outlined
                                        dense
                                        :items="['Telefone', 'CPF', 'CNPJ', 'E-Mail', 'Aleatória']"
                                        label="Tipo da Chave Pix"
                                    />
                                </v-col>
                            </v-row>
                        </v-form>

                        <v-card-actions>
                            <v-btn
                                @click="stepper = 1"
                                text
                                small
                                color="warning"
                            >
                                Voltar
                            </v-btn>
                            <v-spacer/>
                            <v-btn
                                @click="goToThirdStep"
                                color="primary"
                                class="mt-4"
                                width="130"
                                large
                            >Continuar</v-btn>
                        </v-card-actions>

                    </v-stepper-content>

                    <v-stepper-step :complete="stepper > 3" step="3">
                        Configurar Formas e Horários de Atedimento
                    </v-stepper-step>

                    <v-stepper-content step="3">
                        <v-form ref="formAtendimento">
                            <v-row>
                                <v-col>
                                    <span class="caption">
                                        Informe os dias e horários de funcionamento. Exemplo: Segunda a Sexta, das 8h às 18h. Inclua folgas, se houver.
                                    </span>
                                    <v-textarea
                                        v-model="form.horarios_descricao"
                                        :rules="[rules.empty]"
                                        clear-icon="mdi-backspace-outline"
                                        label="Dias e Horários de Atendimento"
                                        rows="5"
                                        class="mt-1"
                                        outlined
                                        dense
                                        clearable
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="ml-1">
                                <v-col class="py-0">
                                    <v-switch
                                        v-model="form.delivery"
                                        dense
                                        inset
                                        hide-details
                                        class="pb-2"
                                        :label="`Tenho serviço de Delivery: [${form.delivery ? 'Sim' : 'Não'}]`"
                                    />

                                    <v-switch
                                        v-model="form.retirada"
                                        dense
                                        inset
                                        class="pb-2"
                                        hide-details
                                        :label="`Meus clientes podem vir retirar o pedido: [${form.retirada ? 'Sim' : 'Não'}]`"
                                    />

                                    <v-switch
                                        v-model="form.local"
                                        dense
                                        inset
                                        class="pb-4"
                                        hide-details
                                        :label="`Meus clientes podem consumir no local: [${form.local ? 'Sim' : 'Não'}]`"
                                    />
                                </v-col>
                            </v-row>

                            <v-row v-if="form.delivery" class="ml-1">
                                <div v-if="form.entregadores.length" style="width: 100%;">
                                    <div class="mb-2">
                                        <span class="text-subtitle-2">Entregadores:</span>
                                    </div>
                                    <div v-for="(entregador, i) in form.entregadores" :key="i">
                                        <v-row>
                                            <v-col>
                                                <v-text-field
                                                    label="Nome do Entregador"
                                                    v-model="entregador.nome"
                                                    outlined
                                                    dense
                                                    hide-details
                                                />
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    label="WhatsApp do Entregador"
                                                    v-model="entregador.whatsapp"
                                                    placeholder="(99) 99999-9999"
                                                    v-mask="['(##) ####-####', '(##) #####-####']"
                                                    hide-details
                                                    outlined
                                                    dense
                                                    small
                                                />
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn
                                                    @click="form.entregadores.splice(i, 1)"
                                                    small
                                                    icon
                                                    class="mr-2"
                                                >
                                                    <v-icon color="error">mdi-delete</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>

                                <v-btn
                                    @click="form.entregadores.push({})"
                                    color="primary"
                                    class="mt-4"
                                    small
                                >Cadastrar entregador</v-btn>
                            </v-row>
                        </v-form>

                        <v-card-actions class="mt-6">
                            <v-btn
                                @click="stepper = 2"
                                text
                                small
                                color="warning"
                            >
                                Voltar
                            </v-btn>
                            <v-spacer/>
                            <v-btn
                                @click="goToFourthStep"
                                color="primary"
                                class="mt-4"
                                width="130"
                                large
                            >Continuar</v-btn>
                        </v-card-actions>
                    </v-stepper-content>

                    <v-stepper-step :complete="stepper > 4" step="4">
                        Configurar Informações Gerais
                    </v-stepper-step>

                    <v-stepper-content step="4">
                        <v-form ref="formGeral">
                            <v-row class="mt-1">
                                <v-col>
                                    <v-text-field
                                        label="Seu WhatsApp Pessoal"
                                        v-model="form.assinatura_whatsapp"
                                        :rules="[rules.whatsapp]"
                                        placeholder="(99) 99999-9999"
                                        v-mask="['(##) ####-####', '(##) #####-####']"
                                        prepend-inner-icon="mdi-whatsapp"
                                        outlined
                                        dense
                                    />
                                </v-col>
                            </v-row>

                            <v-radio-group
                                v-model="form.impressora"
                            >
                                <template v-slot:label>
                                    <span class="text-h6">Possui impressora térmica?</span>
                                </template>
                                <v-radio value="sim" label="Sim" />
                                <v-radio value="nao" label="Não" />
                                <v-radio value="aguardando_chegar" label="Estou aguardando chegar" />
                            </v-radio-group>

                            <v-radio-group
                                v-if="form.delivery"
                                v-model="form.tipo_taxa_entrega"
                            >
                                <template v-slot:label>
                                    <span class="text-h6">Como você cobra as taxas de entrega?</span>
                                </template>
                                <v-radio value="bairro" label="Por bairro" />
                                <v-radio value="distancia" label="Por distância" />
                                <v-radio value="fixa" label="Taxa fixa" />
                                <v-radio value="sem_taxa" label="Não cobramos taxa de entrega" />
                            </v-radio-group>

                            <v-row v-if="form.tipo_taxa_entrega === 'fixa'">
                                <v-col>
                                    <v-text-field
                                        ref="valor"
                                        label="Taxa de entrega"
                                        prefix="R$"
                                        v-model="form.valor_taxa"
                                        placeholder="0.00"
                                        v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                        outlined
                                        dense
                                        hide-details
                                    />
                                </v-col>
                            </v-row>

                            <v-radio-group>
                                <template v-slot:label>
                                    <span class="text-h6">
                                        Utilizará o sistema em quais dispositivos?
                                    </span>
                                </template>
                                <v-checkbox
                                    v-model="form.pc"
                                    label="Computador/Notebook"
                                    hide-details
                                    style="margin-top: 0px;"
                                />
                                <v-checkbox
                                    v-model="form.mobile"
                                    label="Celular/Tablet"
                                    hide-details
                                />
                            </v-radio-group>
                        </v-form>

                        <!-- Quanto tempo em média os pedidos levam para serem entregues? Ex: de 30 a 60min -->
                        <!-- Qual é o Instagram do seu negócio? -->

                        <v-card-actions>
                            <v-btn
                                @click="stepper = 3"
                                text
                                small
                                color="warning"
                            >
                                Voltar
                            </v-btn>
                            <v-spacer/>
                            <v-btn
                                @click="concluir"
                                :loading="loading.concluir"
                                color="primary"
                                class="mt-4"
                                width="130"
                                large
                            >
                                Continuar
                            </v-btn>
                        </v-card-actions>
                    </v-stepper-content>

                    <v-stepper-step :complete="stepper > 5" step="5">
                        Ativar Robô de Atendimento no Whatsapp
                    </v-stepper-step>

                    <v-stepper-content step="5">
                        <v-btn
                            v-if="wppConectError"
                            @click="refreshPairingCode"
                            text
                            small
                            color="primary"
                            class="ma-2"
                        >
                            Tentar novamente
                        </v-btn>
                        <div v-if="loading.pairingCode" style="display: flex; align-items: center;">
                            <v-progress-circular
                                indeterminate
                                color="blue lighten-1"
                                :size="50"
                            />
                            <span class="text-h6 ml-4">Gerando código de conexão...</span>
                        </div>
                        <div v-if="pairingCode">
                            <div class="text-subtitle">
                                Toque na notificação que enviamos no whatsapp do seu celular e digite o código:
                            </div>
                            <v-otp-input
                                v-if="pairingCode"
                                readonly
                                length="8"
                                :value="pairingCode"
                                class="mx-2"
                            />
                            <v-btn
                                @click="refreshPairingCode"
                                text
                                small
                                color="primary"
                                class="ma-2"
                                :disabled="counter > 0"
                            >
                                {{ counter ? `(${counter})` : '' }} Reenviar código
                            </v-btn>
                            <div class="text-subtitle">
                                Caso não tenha recebido a notificação, siga os passos a seguir:
                                <ol class="mt-4">
                                    <li>Abra o WhatsApp no seu celular.</li>
                                    <li>Toque em <b>Mais opções</b> ou <b>Configurações</b> e selecione Dispositivos conectados.</li>
                                    <li>Toque em <b>Conectar com número de telefone</b> e insira o código exibido acima.</li>
                                </ol>
                            </div>
                        </div>
                    </v-stepper-content>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import ufs from '../../utils/ufs';
import { isValidCep } from '../../utils/validate';
import ConfettiManager from '@/utils/confetti';
import { mapState } from 'vuex';

export default {
    name: 'BasicConfigDialog',

    data: () => ({
        stepper: 1,
        form: {
            entregadores: [],
            delivery: true,
            retirada: true,
            local: true,
        },
        loading: {
            cep: false,
            concluir: false,
            pairingCode: false,
        },
        ufs: [],
        rules: {
            empty: value => !!value?.trim() || 'Preenchimento obrigatório',
            cep: value => isValidCep(value) || 'CEP inválido',
            whatsapp: value => value?.length >= 14 || 'Whatsapp inválido',
        },
        pairingCode: '',
        wppInstanceKey: null,
        wppConectError: false,
        wppConected: false,
        counter: 60,
        counterInterval: null,
        wppCheckInterval: null,
        wppCheckCount: 0,
        finished: false,
        confettiManager: null,
    }),

    mounted() {
        this.confettiManager = new ConfettiManager();
        this.ufs = ufs;
        this.initWppInstance();
        this.ws();
    },

    beforeDestroy() {
        document.removeEventListener('ws-open', this.infoWppInstance);
    },

    computed: {
        ...mapState([
            'usuario',
            'configuracoes',
        ]),

        getUserName() {
            const name = this.usuario?.name || '';
            return name.split(' ')[0];
        },
    },

    watch: {
        'form.cep'(newVal) {
            if (newVal.length === 9) {
                this.consultaCep();
            }
        },

        stepper(v) {
            if (v === 5) {
                this.connectWithPairingCode();
                this.startWppConnectionCheck();
            }
        },

        finished(v) {
            if (v) {
                this.confettiManager.resetAndStart({
                    confettiesNumber: 250,
                    confettiRadius: 6,
                    confettiColors: [
                        '#fcf403', '#62fc03', '#f4fc03', '#03e7fc',
                        '#03fca5', '#a503fc', '#fc03ad', '#fc03c2',
                    ],
                    emojies: [],
                    svgIcon: null
                });
            }
        },
    },

    methods: {
        async consultaCep() {
            if (!this.form.cep) {
                return;
            }

            if (!isValidCep(this.form.cep)) {
                this.notify('Informe um CEP válido', 'warning');
                return;
            }

            let resp;

            this.loading.cep = true;
            try {
                const request = await fetch(`https://viacep.com.br/ws/${this.form.cep}/json`, {
                    method: 'GET',
                });
                resp = await request.json();

                this.form.uf = resp.uf;
                this.form.bairro = resp.bairro;
                this.form.municipio = resp.localidade;
                this.form.rua = resp.logradouro;
            } catch (error) {
                this.notify(error, 'warning');
            } finally {
                this.loading.cep = false;
            }
        },

        goToSecondStep() {
            if (!this.$refs.formEndereco.validate()) {
                this.notify('Formulário contém erros', 'warning');
                return;
            }

            this.stepper = 2;
        },

        goToThirdStep() {
            if (!this.$refs.formPix.validate()) {
                this.notify('Formulário contém erros', 'warning');
                return;
            }

            this.stepper = 3;
        },

        goToFourthStep() {
            if (!this.$refs.formAtendimento.validate()) {
                this.notify('Formulário contém erros', 'warning');
                return;
            }

            this.stepper = 4;
        },

        concluir() {
            if (!this.$refs.formGeral.validate()) {
                this.notify('Formulário contém erros', 'warning');
                return;
            }

            if (!this.form.impressora) {
                this.notify('Possui impressora térmica?', 'warning');
                return;
            }

            if (!this.form.tipo_taxa_entrega) {
                this.notify('Como você cobra as taxas de entrega?', 'warning');
                return;
            }

            if (!this.form.pc && !this.form.mobile) {
                this.notify('Você vai utilizar o sistema em quais dispositivos?', 'warning');
                return;
            }

            this.save();
        },

        save() {
            this.loading.concluir = true;
            this.$http.post('configuracoes/set-initial-setup ', this.form)
                .then(resp => {
                    if (resp.data.type == 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }

                    this.stepper = 5;
                })
                .catch(() => this.notify('Não foi possivel salvar', 'warning'))
                .finally(() => (this.loading.concluir = false));
        },

        async initWppInstance() {
            this.loading.pairingCode = true;
            await this.$http.get('whatsapp-instance/init').then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning')
                    this.wppConectError = true;
                    return;
                }

                if (!resp.data.data?.key) {
                    this.wppConectError = true;
                    return;
                }

                this.wppInstanceKey = resp.data.data.key;
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
                this.wppConectError = true;
            })
            .finally(() => (this.loading.pairingCode = false));
        },

        async connectWithPairingCode() {
            this.loading.pairingCode = true;
            await this.$http.get(`whatsapp-instance/pairing-code?key=${this.wppInstanceKey}`).then(async resp => {
                if (!resp.data.data.code) {
                    this.wppConectError = true;
                    this.notify('Não foi possivel concluir a operação, tente novamente', 'warning');
                    return;
                }
                this.pairingCode = resp.data.data.code;
                this.startCounter();
            })
            .catch(() => {
                this.notify('Não foi possivel concluir a operação', 'warning');
                this.wppConectError = true;
            })
            .finally(() => (this.loading.pairingCode = false));
        },

        async refreshPairingCode() {
            this.pairingCode = '';
            this.wppConectError = false;
            this.loading.pairingCode = true;
            clearInterval(this.counterInterval);
            this.counter = 60;

            await this.initWppInstance();
            await this.connectWithPairingCode();
            this.loading.pairingCode = false;
        },

        startCounter() {
            this.counter = 60;
            clearInterval(this.counterInterval);

            this.counterInterval = setInterval(() => {
                if (this.counter <= 0) {
                    clearInterval(this.counterInterval);
                    return;
                }
                this.counter--;
            }, 1000);
        },

        startWppConnectionCheck() {
            this.wppCheckCount = 0;
            clearInterval(this.wppCheckInterval);

            this.wppCheckInterval = setInterval(async () => {
                if (this.wppCheckCount >= 20 || this.wppConected) {
                    clearInterval(this.wppCheckInterval);
                    return;
                }

                await this.infoWppInstance();
                this.wppCheckCount++;
            }, 15000);
        },

        ws() {
            document.addEventListener('ws-open', this.infoWppInstance);
        },

        async infoWppInstance() {
            const { data } = (await this.$http.get('whatsapp-instance/info')).data;

            this.wppConected = data?.instance_data?.phone_connected;

            if (this.wppConected) {
                this.finished = true;
                clearInterval(this.wppCheckInterval);
            }
        },
    },
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

@media only screen and (max-width: 768px) {
    .v-card__text {
        padding: 0 !important;
    }

    .v-stepper__content {
        margin: -8px -36px -16px 2px !important;
    }
}

.v-badge {
    display: inline;
}
</style>
