<template>
    <BaseContainer
        style="background: #D5D6D5; background: linear-gradient(to bottom right, #fff, #999);"
    >
        <span slot="principal">
            <v-content>
                <div v-if="!$vuetify.breakpoint.lgAndUp">
                    <v-text-field
                        @blur="setPesquisaComanda(true)"
                        v-model="pesquisaComanda.value"
                        ref="pesquisaComanda"
                        dense
                        outlined
                        clearable
                        hide-details
                        color="#222"
                        label="Pesquise o pedido"
                        placeholder="Pesquise pelo cliente ou mesa"
                        prepend-inner-icon="mdi-magnify"
                        :style="{
                            position: 'absolute',
                            top: '10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            'background-color': '#fff',
                            '-webkit-box-shadow': '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
                            'z-index': 1,
                            width: '300px',
                            display: pesquisaComanda.hidden ? 'none' : undefined,
                        }"
                    />
                    <v-tooltip v-if="!filterOpen" left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                @click="filterOpen = true"
                                v-bind="attrs"
                                v-on="on"
                                fab
                                small
                                color="gray"
                                :style="`position: fixed; top: ${isDesktop ? '75px' : '67px'}; right: ${isDesktop ? '400px' : '1px'}; z-index: 2;`"
                            >
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                            <v-icon
                                v-if="filter.length"
                                color="primary"
                                size="40"
                                :style="`position: fixed; top: ${isDesktop ? '60px' : '52px'}; right: ${isDesktop ? '387px' : '-13px'}; z-index: 3;`"
                            >
                                mdi-circle-medium
                            </v-icon>
                        </template>
                        <span>Filtrar pedidos</span>
                    </v-tooltip>
                    <div v-else style="position: absolute; top: 10px; right: 1px; z-index: 1; display: flex;">
                        <v-select
                            v-model="filter"
                            :items="filters"
                            placeholder="Filtros"
                            outlined
                            dense
                            hide-details
                            multiple
                            small-chips
                            clearable
                            @change="onFilterChange"
                            class="white-and-shadow"
                            style="width: 250px;"
                        >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                    <span>{{ item }}</span>
                                </v-chip>
                                <span v-if="index === 1" class="grey--text text-caption">
                                (+{{ filter.length - 1 }} outros)
                                </span>
                            </template>
                        </v-select>
                        <v-btn
                            @click="filterOpen = false"
                            fab
                            color="white"
                            class="ml-1 elevation-8"
                            small
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                    <div>
                        <v-btn
                            @click="setPesquisaComanda()"
                            fab
                            small
                            :style="`position: fixed; top: ${isDesktop ? '120px' : '112px'}; right: ${isDesktop ? '400px' : '1px'}; z-index: 2;`"
                        >
                            <v-icon>mdi-magnify</v-icon>
                        </v-btn>
                    </div>
                </div>

                <div v-else class="ma-2" style="display: flex;">
                    <v-select
                        v-model="filter"
                        :items="filters"
                        placeholder="Filtros"
                        prepend-inner-icon="mdi-filter"
                        outlined
                        dense
                        hide-details
                        multiple
                        small-chips
                        clearable
                        @change="onFilterChange"
                        class="white-and-shadow filters-desktop mr-2"
                    >
                        <!-- <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                                <span>{{ item }}</span>
                            </v-chip>
                            <span v-if="index === 2" class="grey--text text-caption">
                            (+{{ filter.length - 1 }} outros)
                            </span>
                        </template> -->
                    </v-select>

                    <v-text-field
                        v-model="pesquisaComanda.value"
                        dense
                        outlined
                        clearable
                        hide-details
                        label="Pesquise o pedido"
                        placeholder="Nome do cliente ou nº mesa"
                        prepend-inner-icon="mdi-magnify"
                        :style="{
                            'background-color': '#fff',
                            '-webkit-box-shadow': '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
                            'z-index': 1,
                            'min-width': '260px',
                        }"
                    />
                </div>
                <v-container
                    v-if="!comandasLoading"
                    @click="setPesquisaComanda(true)"
                    fluid
                >
                    <div class="flex-container">
                        <Card
                            v-for="pedido in filtroPedidos()"
                            :key="pedido.id"
                            :pedido="pedido"
                            :configuracoes="configuracoes"
                            :formasEntrega="formasEntrega"
                            :formasPagamento="formasPagamento"
                            @onPedidoClick="editarPedido(pedido)"
                            @imprimir="onClickImprimir"
                            @onUpdateStatus="onUpdateStatus"
                        />
                    </div>
                </v-container>

                <v-container fluid v-else>
                    <div class="flex-container">
                        <v-skeleton-loader v-for="i in 4" :key="i"
                            type="card"
                            width="140" min-height="70"
                        />
                    </div>
                </v-container>

                <v-container fluid v-if="!comandasLoading && !comandas.length">
                    <v-card-title class="justify-center">Nenhum pedido por aqui!</v-card-title>
                    <v-card-title v-if="isDesktop" class="justify-center">
                        Utilize a "Comanda Rápida" para criar um novo.
                    </v-card-title>
                    <v-card-title v-else class="justify-center">
                        Toque no botão <v-icon small class="mx-1">mdi-note-edit</v-icon> para criar um novo.
                    </v-card-title>
                </v-container>

                <DialogComanda
                    v-model="comandaSelecionada"
                    :visible="dialogComanda"
                    @close="dialogComanda = false"
                    @onUpdateStatus="onUpdateStatus"
                    @imprimir="onClickImprimir"
                />

                <DialogTermos
                    :visible="dialogTermos"
                    @close="dialogTermos = false"
                />

                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click="dialogHomeConfig = true"
                            fab
                            color="gray"
                            :style="`position: fixed; bottom: 110px; right: ${isDesktop ? '410px' : '10px'}; z-index: 2;`"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-sort-descending</v-icon>
                        </v-btn>
                    </template>
                    <span>Ordenar pedidos</span>
                </v-tooltip>

                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            @click="novaComanda"
                            fab
                            :style="`position: fixed; bottom: 15px; right: ${isDesktop ? '410px' : '10px'}; z-index: 2;`"
                            x-large
                            color="primary"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-note-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>Novo pedido</span>
                </v-tooltip>
            </v-content>

            <PedidoSlider
                v-if="isDesktop"
            />

            <HomeConfig
                v-if="dialogHomeConfig"
                @save="onHomeConfigSave"
                @close="dialogHomeConfig = false"
            />

            <EntregadorSelectDialog
                v-model="entregadorSelect.pedido"
                :visible="entregadorSelect.visible"
                @close="entregadorSelect.visible = false"
            />

            <BasicConfigDialog
                v-if="dialogBasicConfigVisible"
                @close="basicConfigClose"
            />

            <Chat
                v-if="usuario.tipo === 'suporte'"
                class="chat-button"
            />

            <WhatsappSupportButton class="whatsapp-button" />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer.vue';
import Card from './Card.vue';
import HomeConfig from './HomeConfig.vue';
import BasicConfigDialog from './BasicConfigDialog.vue';
import DialogComanda from './DialogComanda.vue';
import DialogTermos from '@/pages/termos/TermosUso.vue';
import PedidoSlider from './Pedido/PedidoSlider.vue';
import EntregadorSelectDialog from '@/components/EntregadorSelectDialog.vue';
import WhatsappSupportButton from '@/components/WhatsappSupportButton.vue';
import Chat from '@/components/Chat/Index.vue';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'Home',

    components: {
        BaseContainer,
        Card,
        Chat,
        HomeConfig,
        DialogComanda,
        DialogTermos,
        PedidoSlider,
        EntregadorSelectDialog,
        WhatsappSupportButton,
        BasicConfigDialog,
    },

    data: () => ({
        filterOpen: false,
        filter: [],
        filters: [
            { header: 'Status' },
            'Pendente',
            'Pronto',
            { header: 'Tipo' },
            'Delivery',
            'Retirar no Balcao',
            'Mesa',
            'Agendado',
            'Não Agendado',
        ],
        pesquisaComanda: {value: '', hidden: true},
        comandasLoading: false,
        comandaSelecionada: {
            id: 'auto',
            cliente: null,
            cliente_id: null,
            pronto: false,
            impresso: false,
            mesa: '1',
            pessoas: '1',
            entrega: 3,
            status: 'confirmado',
            produtos: [],
        },
        comandas: [],
        dialogTermos: false,
        dialogComanda: false,
        dialogHomeConfig: false,
        dialogBasicConfigVisible: false,
        loading: false,
        loadingWhatsapp: false,
        entregadorSelect: {pedido: null, visible: false},
        progressbarInterval: null,
        autoRefresh: null,
    }),

    computed: {
        ...mapState([
            'usuario',
            'configuracoes',
            'formasEntrega',
            'formasPagamento',
            'entregadores',
            'socket',
            'soundAlert',
            'isDashboardInitialized',
        ]),

        usuarioImpressao() {
            const { id, usuario_impressao } = this.usuario;

            if (usuario_impressao) {
                return id === usuario_impressao;
            }

            return [null, id].includes(this.configuracoes.usuario_impressao);
        },

        isDesktop() {
            return this.$vuetify.breakpoint.mdAndUp;
        },

        requireSupervisorPassword() {
            return !this.usuario.admin && this.configuracoes.supervisor_password;
        },
    },

    async mounted() {
        this.$root.$on('listarComandas', () => (this.consultar()));

        this.ws();

        this.consultar();

        this.autoRefresh = setInterval(() => this.consultar(false), 120000);
        this.progressbarInterval = setInterval(() => this.updateOrdersProgress(), 60000);
    },

    beforeDestroy() {
        this.$root.$off('listarComandas');

        document.removeEventListener('ws-order-event', this.wsOrderEvent);
        document.removeEventListener('ws-update-all-orders', this.consultar);

        this.soundAlert.pause();

        clearInterval(this.progressbarInterval);
        clearInterval(this.autoRefresh);
    },

    watch: {
        'comandaSelecionada.produtos'() {
            this.comandaSelecionada.produtos.forEach((produto, index) => {
                produto.index = index + 1;
                return produto;
            });
        },

        isDashboardInitialized(v) {
            if (v && this.configuracoes.cep === '-') {
                this.dialogBasicConfigVisible = true;
            }
        },
    },

    methods: {
        ...mapActions([
            'sendPrintQueue',
        ]),

        ws() {
            document.addEventListener('ws-order-event', this.wsOrderEvent);
            document.addEventListener('ws-update-all-orders', this.consultar);
        },

        wsOrderEvent(event) {
            this.orderEvent(event.detail);
        },

        async onClickImprimir({ pedido, tipo }) {
            if (this.usuarioImpressao) {
                const data = { pedido: pedido.id, tipo };
                this.$root.$emit('imprimir', data);
                return;
            }

            this.socket.emit('print', { pedidoId: pedido.id, tipo, user: this.usuario });
            this.notify('Impressão enviada');
        },

        async onUpdateStatus({id, status, pronto}) {
            const STATUS_CANCELADO = 'cancelado';
            const data = { status, pronto };

            if ([STATUS_CANCELADO].includes(status) && this.requireSupervisorPassword) {
                data.supervisor_password = prompt('Digite a senha de supervisão');

                if (!data.supervisor_password) {
                    return;
                }
            }

            this.soundAlert.pause();

            this.$root.$emit('loading', true);
            this.$http.post(`pedidos/update-status/${id}`, data).then(resp => {
                if (resp.data.type == 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }

                this.notify('Status alterado com sucesso');
                this.showEntregadorSelect(id, status);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => {
                this.$root.$emit('loading', false);
            });
        },

        showEntregadorSelect(pedido, status) {
            if (status != 'saiu_para_entrega' || !this.entregadores?.length) {
                return;
            }

            this.entregadorSelect = {pedido, visible: true};
        },

        onHomeConfigSave() {
            this.dialogHomeConfig = false;
            this.sortComandas();
        },

        sortComandas() {
            const sortField = localStorage.getItem('zm-dashboard-sort-field') || 'codigo';
            const sortOrder = localStorage.getItem('zm-dashboard-sort-order') || 'desc';

            const sortByDate = (a, b) => {
                const getDate = ({ agendado_datahora, created_at }) => {
                    console.log(agendado_datahora?.split(' - '))
                    return agendado_datahora
                        ? agendado_datahora.split(' - ')[0]
                        : created_at;
                }
                if (sortOrder === 'asc') {
                    [a, b] = [b, a];
                }
                return this.moment(getDate(b)).diff(this.moment(getDate(a)));
            };

            const sortById = (a, b) => {
                if (sortOrder === 'asc') {
                    [a, b] = [b, a];
                }
                return a.id > b.id ? -1 : 1;
            }

            const sort = sortField === 'prazo_entrega' ? sortByDate : sortById;

            this.comandas.sort(sort);
            this.onFilterChange();
        },

        novaComanda() {
            this.comandaSelecionada = {
                id: 'auto',
                cliente: null,
                cliente_id: null,
                mesa: 1,
                pronto: false,
                impresso: false,
                pessoas: 1,
                entrega: 3,
                observacao: '',
                status: 'confirmado',
                produtos: [],
            };
            this.dialogComanda = true;
        },

        editarPedido(pedido) {
            this.comandaSelecionada = pedido;
            this.dialogComanda = true;
        },

        onFilterChange() {
            this.comandas.forEach(e => {
                e.hidden = true;

                if (!this.filter.length) {
                    e.hidden = false;
                    return;
                }

                if (
                    this.filter.includes('Pronto') && e.pronto ||
                    this.filter.includes('Pendente') && !e.pronto ||
                    this.filter.includes('Delivery') && e.entrega === 2 ||
                    this.filter.includes('Retirar no Balcao') && e.entrega === 1 ||
                    this.filter.includes('Mesa') && e.entrega === 3 ||
                    this.filter.includes('Agendado') && e.agendado ||
                    this.filter.includes('Não Agendado') && !e.agendado
                ) {
                    e.hidden = false;
                }
            });
            this.$forceUpdate();
        },

        async consultar(showLoading = true) {
            if (showLoading) {
                this.comandasLoading = true;
            }

            await this.$http.get('pedidos').then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                    return;
                }

                this.comandas = resp.data?.data || [];

                setTimeout(() => this.shouldPlayAlertSound(), 1500);

                this.updateOrdersProgress();
                this.sortComandas();
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            })
            .finally(() => {
                this.comandasLoading = false;
            });
        },

        filtroPedidos() {
            let pesquisa = this.pesquisaComanda.value?.toLowerCase();

            if (!pesquisa) {
                return this.comandas.filter(c => !c.hidden);
            }

            return this.comandas.filter(c => {
                const mesaMatch = (c.mesa || '')?.toString()?.toLowerCase()?.includes(pesquisa);
                const clienteMatch = c.cliente?.nome?.toLowerCase().includes(pesquisa);
                const dataMatch = c.created_at.includes(pesquisa);
                const agendadoMatch = c.agendado_datahora?.includes(pesquisa);

                return !c.hidden && (clienteMatch || mesaMatch || dataMatch || agendadoMatch);
            });
        },

        setPesquisaComanda(hidden) {
            if (this.pesquisaComanda.value) {
                return;
            }

            this.pesquisaComanda.hidden = hidden || !this.pesquisaComanda.hidden;

            if (this.pesquisaComanda.hidden) {
                this.pesquisaComanda.value = '';
                return;
            }

            this.$nextTick(() => this.$refs.pesquisaComanda?.focus())
        },

        shouldPlayAlertSound() {
            const hasPending = this.comandas.some(e => e.status === 'recebido');
            hasPending
                ? this.soundAlert.play()
                : this.soundAlert.pause();
        },

        async orderEvent(payload) {
            if (payload.type === 'CREATE') {
                const order = await this.getOrderById(payload.orderId);

                this.comandas.push(order);
                this.sortComandas();
                this.updateOrdersProgress();
            }

            if (payload.type === 'UPDATE') {
                const order = await this.getOrderById(payload.orderId);

                const index = this.comandas.findIndex(c => c.id === payload.orderId);
                this.comandas[index] = order;

                setTimeout(() => this.shouldPlayAlertSound(), 1500);

                this.$forceUpdate();
            }

            if (payload.type === 'DELETE') {
                const index = this.comandas.findIndex(c => c.id === payload.orderId);
                this.comandas.splice(index, 1);

                setTimeout(() => this.shouldPlayAlertSound(), 1500);
            }
        },

        async getOrderById(id) {
            const resp = await this.$http.get('pedidos/' + id);
            return { ...resp.data, ...{ prazo_status: null, progresso: null }};
        },

        getProgress({ prazo_finalizacao, created_at }) {
            if (!prazo_finalizacao) {
                return null;
            }

            const diff = this.moment(prazo_finalizacao).diff(this.moment(created_at), 'minutes');
            const diff2 = this.moment(this.moment()).diff(created_at, 'minutes');
            const progress = (diff2 * 100) / diff;

            return progress;
        },

        getPrazoStatus({ prazo_finalizacao, pronto, status }) {
            const finishedStatus = ['saiu_para_entrega', 'aguardando_retirada'];

            if (!prazo_finalizacao || pronto || finishedStatus.includes(status)) {
                return '';
            }

            const diff = this.moment(prazo_finalizacao).diff(this.moment(), 'minutes');

            const text = diff >= 0
                ? {
                    atrasado: false,
                    text: `Restam ${diff < 60 ? diff : '+60'} min`
                }
                : {
                    atrasado: true,
                    text: `Atrasado ${-diff < 60 ? -diff : '+60'} min`
                };

            return text;
        },

        updateOrdersProgress() {
            this.comandas = this.comandas
                .map(e => ({...e, ...{ prazo_status: null, progresso: null }}))
                .filter(e => {
                    e.prazo_status = this.getPrazoStatus(e);
                    e.progresso = this.getProgress(e);
                    return true;
                });
        },

        basicConfigClose() {
            this.dialogBasicConfigVisible = false;
        },
    },
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0;
    margin: 0;
    list-style: none;
}

.v-card__text, .v-card__title {
    word-break: normal;
    /* maybe !important  */
}

.v-card__title {
    padding: 0 16px 0 16px;
    font-size: 15px;
    height: 35px !important;
}

::v-deep .white-and-shadow .v-input__control {
    background-color: #fff;
    -webkit-box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}


::v-deep .filters-desktop .v-select__selections {
    max-height: 30px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    overflow: hidden;
}

::v-deep .filters-desktop .v-select__selections::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.line-through {
    text-decoration: line-through;
}

.chat-button {
    position: absolute;
    left: -10px;
    bottom: 60px;
}

.whatsapp-button {
    position: absolute;
    left: 5px;
    bottom: 5px;
}
</style>
